<template>
  <div class="container">
    <div class="banner">
      <img src="~@/assets/images/incentiveVideo/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="total">
        累计领取
        <span>{{ vipDays }}</span>
        天会员
      </div>
      <ul class="task-list">
        <li
          class="task-li"
          v-for="(item, index) in taskList"
          :key="`task_${index}`"
        >
          <div class="task-img">
            <img :src="item.iconImg" alt="" />
          </div>
          <div class="task-info">
            <div class="task-title">{{ item.title }}</div>
            <p class="task-rule" v-html="item.desc"></p>
          </div>
          <div
            class="task-btn"
            :class="{ isComplete: item.isComplete }"
            @click="clickAction(item.type, item.isComplete)"
          >
            {{ item.btnText }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { getUserStatus } from '@/api/base';
import { getAppStatus, watchVideo } from '@/utils/app';

export default {
  name: 'IncentiveVideo',
  data() {
    return {
      vipDays: 0, // 累计会员天数
      advertisements: [],
    };
  },
  mounted() {
    this.userStatus();
  },
  computed: {
    taskList() {
      const videoData = this.advertisements.find(
        (item) => item.type === 'video',
      );
      console.log(videoData);
      return this.advertisements.length
        ? [
          {
            iconImg:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAYFBMVEX49fHoeXn////42trqhoXvpKP26OTpgoLofHz+/Pz++Pj41tbzubn76en98/P87+/53d32zc30wMDtmprsk5Prj4/qiIjpf3/65OTxr6/wqanun5/2z8/1x8f1xsbxsbHrMc7+AAABCklEQVRo3u3ay27CQAxG4Xo6IVegoWmB0sv7v2WrCmSxmg3jH6Fzdll9m8iWRn4iIiIiIkGrJtvNy82q6LZWpbYkN1appgBnq1QuwFYtYOBLwMDAwMDAwFaqm+aTmQCeUkrjuwB+Tn8NUyeA/1u/xsPn5iyC03AMhr3DIoJT/72Phb3NRzDsvbyJ4NTvuljY2540sA/RYNiHqAD2IRoGe0cVvFbBowgeWwm8bSR/db/bmwD2eR0F+4YSwP1Xawr4sEj28fBjpoA/swlgXwtRsC9CAeyrPwz2ARkLzz4gY+Fl4wMyFLYuX30+/lMEMDAwMDCwAT8sXO8A5V5PbuodGd3rWRURERER3bxfpV8jFCYtc84AAAAASUVORK5CYII=',
            title: '看视频 领2天会员',
            desc: `<span>${videoData.todayCounts}/${videoData.threshold}</span>次 已领取${videoData.userVipCount}天 每天<span>限${videoData.threshold}次</span><br/>看2次视频领1天会员~`,
            type: 'video',
            btnText:
                videoData.todayCounts < videoData.threshold
                  ? '看视频'
                  : '已完成',
            isComplete: videoData.todayCounts >= videoData.threshold,
          },
        ]
        : [];
    },
    userId() {
      // "6bbf384d-dacd-472e-8c9f-d013c59d20b9"
      return getAppStatus().cuserId;
    },
  },
  methods: {
    async userStatus() {
      let res;
      const { userId } = this;
      try {
        res = await getUserStatus({ userId });
      } catch (e) {
        console.error(e);
        this.$toast({
          content: e.desc,
          duration: 3000,
        });
      }
      if (res.code === 1) {
        const { results } = res;
        this.advertisements = results?.advertisements || [];
        this.vipDays = results?.userTotalVipCount || 0;
      } else {
        this.advertisements = [];
      }
    },
    clickAction(type, isComplete) {
      if (type === 'video') {
        if (isComplete) {
          return this.$toast({ content: '今日视频观看次数已达上限' });
        }
        watchVideo(); // 调取观看视频
      }
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  min-height: 100%;
  .content {
    background: linear-gradient(180deg, #e3cfb4, #dcbe98);
  }
  .banner {
    width: 100%;
    text-align: center;
    background: #e3cfb4;
    img {
      width: 100%;
    }
  }
  .total {
    padding-top: 10px;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
  }
  .task-list {
    background: linear-gradient(180deg, #e3cfb4, #dcbe98);
    padding: 0 0.4267rem 0.6933rem;
    .task-li {
      display: flex;
      position: relative;
      margin-top: 0.2667rem;
      height: 2.4rem;
      background: #fff;
      overflow: hidden;
      .task-img {
        margin: 0.4rem 0.4rem 0.4rem 0.2667rem;
        width: 1.6rem;
        height: 1.6rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .task-info {
        flex: 1;
        .task-title {
          margin-top: 0.4533rem;
          font-size: 0.4267rem;
          font-weight: 500;
          color: #333;
        }
        .task-rule {
          margin-top: 0.16rem;
          font-size: 0.3467rem;
          color: #777;
          /deep/ span {
            color: #d25b58;
          }
        }
      }
      .task-btn {
        position: absolute;
        top: 0.6933rem;
        right: 0.3733rem;
        width: 1.76rem;
        height: 0.96rem;
        line-height: 0.96rem;
        text-align: center;
        font-size: 0.3733rem;
        color: #895f26;
        border-radius: 0.48rem;
        background: #e4c894;
        &.complete {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
